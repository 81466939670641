import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { http } from '../../utils/Utils';
import Button from '../../components/UI/buttons/Button';
import Modal from '../../components/UI/Modal';
import Loader from '../../components/UI/Loader';
import FormLabel from '../../components/UI/FormLabel';
import { TextField } from '../../components/Form/TextField';
import { SelectField } from '../../components/Form/SelectField';

export function ModalDuplicatePricelist({
  isVisible,
  handleCancel,
  redirectToNewPriceList,
  priceListId,
}) {
  const form = useForm();
  const history = useHistory();
  const [showLoading, setShowLoading] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  useEffect(() => {
    form.setValue('priceChange', 'no');
  }, []); // eslint-disable-line

  const onSubmit = async (data) => {
    try {
      setShowErrorModal(false);
      setShowLoading(true);

      const priceList = await http({
        url: `/pricing/priceLists/${priceListId}/duplicate`,
        method: 'POST',
        form: data,
      });
      setShowLoading(false);
      redirectToNewPriceList(priceList._id);
    } catch (err) {
      setShowErrorModal(true);
    }
  };

  return (
    <Modal
      headerChildren={showLoading ? 'In elaborazione...' : 'Stai per duplicare il listino'}
      isVisible={isVisible}
      onClose={handleCancel}
    >
      {showLoading ? (
        <div className="w-160 h-32 flex items-center">
          <Loader />
        </div>
      ) : (
        <div className="w-96">
          <div>
            <>
              <strong>Duplica Listino</strong>
              <br />
            </>

            <>Sei sicuro di voler duplicare il listino?</>
          </div>
          <form onSubmit={form.handleSubmit(onSubmit)} id="priceListForm">
            <div className="lg:min-w-96">
              <div className="w-96 mr-3">
                <FormLabel>Sconto/Maggiorazione Tariffe</FormLabel>
                <SelectField
                  name="priceChange"
                  form={form}
                  options={[
                    { value: 'no', label: 'Prezzo Invariato' },
                    { value: 'percentageDecrease', label: 'Sconto' },
                    { value: 'percentageIncrease', label: 'Maggiorazione' },
                  ]}
                  onChangeFunction={(e) => {
                    form.setValue('percentageDecrease', 0);
                    form.setValue('percentageIncrease', 0);
                  }}
                />
              </div>
              {form.watch('priceChange') === 'percentageDecrease' && (
                <div className="w-96 mr-3">
                  <FormLabel>Sconto Percentuale</FormLabel>
                  <div className="w-full flex items-center">
                    <TextField
                      name="percentageDecrease"
                      form={form}
                      type="number"
                      min={0}
                      max={100}
                      step={1}
                      className="flex-grow"
                      onChange={(e) => {
                        const value = Math.min(Math.max(e.target.value, 0), 100);
                        form.setValue('percentageDecrease', value);
                      }}
                    />
                    <span className="ml-2 bg-gray-200 border border-gray-300 rounded px-2 py-1 text-sm font-semibold">
                      %
                    </span>
                  </div>
                </div>
              )}
              {form.watch('priceChange') === 'percentageIncrease' && (
                <div className="w-96 mr-3">
                  <FormLabel>Maggiorazione Percentuale</FormLabel>
                  <div className="w-full flex items-center">
                    <TextField
                      name="percentageIncrease"
                      form={form}
                      type="number"
                      min={0}
                      max={100}
                      step={1}
                      className="flex-grow"
                      onChange={(e) => {
                        const value = Math.min(Math.max(e.target.value, 0), 100);
                        form.setValue('percentageIncrease', value);
                      }}
                    />
                    <span className="ml-2 bg-gray-200 border border-gray-300 rounded px-2 py-1 text-sm font-semibold">
                      %
                    </span>
                  </div>
                </div>
              )}

              <div className="text-right mt-3">
                <Button className="!py-1 w-32">Duplica</Button>
              </div>
              {showErrorModal && (
                <div className="text-red-700 bg-red-200 rounded-lg p-4 mt-3 text-md">
                  Non è possibile duplicare questo listino
                </div>
              )}
            </div>
          </form>
        </div>
      )}
    </Modal>
  );
}

export default ModalDuplicatePricelist;
