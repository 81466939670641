import React, { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { MOVOLAB_ROLE_ADMIN, CLIENT_ROLE_ADMIN, http } from '../../utils/Utils';
import { UserContext } from '../../store/UserContext';
import WidgetConfigTableItem from './WidgetConfigTableItem';

const WidgetConfigTable = ({ clientId }) => {
  const userContext = useContext(UserContext);
  let userData = userContext.data || {};

  const [widgets, setWidgets] = useState([]);
  const [widgetsCount, setWidgetsCount] = useState(0);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  if (userData.role === CLIENT_ROLE_ADMIN) {
    clientId = userData.client?._id;
  }

  useEffect(() => {
    fetchWidgets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchWidgets = async () => {
    try {
      const response = await http({
        url: `/widgets`,
      });

      setWidgets(response.widgets);
      setWidgetsCount(response.count);
      setIsDataLoaded(true);
    } catch (err) {
      console.error(err);
      toast.error(err?.reason?.error || 'Errore');
    }
  };

  if (!isDataLoaded) {
    return <></>;
  }

  if (widgetsCount === 0) {
    return (
      <div className="p-5 bg-gray-100 rounded-md font-semibold">
        <div className="">Nessun Widget Creato</div>
      </div>
    );
  }

  return (
    <div className="bg-white border-t border-gray-200 overflow-hidden relative">
      <div className="overflow-x-auto h-full">
        {/* Table */}
        <div>
          <table className="table-auto w-full">
            {/* Table header */}
            <thead className="text-xs font-semibold uppercase text-gray-500 bg-gray-50">
              <tr>
                <th className="px-5 py-3 whitespace-nowrap w-1/6">
                  <div className="font-semibold text-left">
                    <span>Nome</span>
                  </div>
                </th>
                <th className="px-5 py-3 whitespace-nowrap w-1/6">
                  <div className="font-semibold text-left">
                    <span>Widget ID</span>
                  </div>
                </th>
                <th className="px-5 py-3 whitespace-nowrap w-1/6">
                  <div className="font-semibold text-left">
                    <span>Flusso Paga Ora</span>
                  </div>
                </th>
                <th className="px-5 py-3 whitespace-nowrap w-1/6">
                  <div className="font-semibold text-left">
                    <span>Flusso Paga Dopo</span>
                  </div>
                </th>
                <th className="px-5 py-3 whitespace-nowrap w-1/6">
                  <div className="font-semibold text-left">
                    <span>Data Creazione</span>
                  </div>
                </th>
                <th className="px-5 py-3 whitespace-nowrap w-1/6">
                  <div className="font-semibold text-left">
                    <span></span>
                  </div>
                </th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="text-sm divide-y divide-gray-200 border-t border-b border-gray-200">
              {widgets.map((widget, index) => {
                return <WidgetConfigTableItem key={index} widget={widget} role={userData?.role} />;
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default WidgetConfigTable;
